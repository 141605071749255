import { combineReducers } from 'redux'
import results from './results'
import suggestions from './suggestions'
import uploads from './upload'
import justification from './justification'
import searchType from './searchType'

export default combineReducers({
  justification,
  results,
  suggestions,
  uploads,
  searchType
})
