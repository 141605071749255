import { DataSourceType } from '../constants/sourceTypes'
import { IBulkExportFilters } from '../modules/BulkExport/state/download'
import {
  FilterInputType,
  FilterType
} from './filters'
import {
  Pass,
  Person
} from './PassHolder'

export type ApiResult = {
  success: boolean
  statusCode?: number
}

export type ApiSuccessResult<TData> = ApiResult & {
  data: TData
  success: true
}

export type ApiFailurePayload = {
  reason: string
}

export type ApiFailureResult<TData> = ApiResult & {
  data: TData
  success: false
}

export interface AccountActivitySummary {
  id: string
  username: string
  organisations: string[]
  groups: string[]
  status: AccountStatus[]
  actions: RequiredActions[]
  lastLogin: string
  otp: boolean
}

export type AccountStatus = 'ENABLED' | 'DISABLED' | 'PENDING_DELETION'

export type RequiredActions = 'SET_PASSWORD' | 'SET_OTP'

export interface RepeatChecksAuditSummary {
  id: string
  status: RepeatChecksAuditStatus
  timestamp: string
  group: number
  record_count: number
}

export type RepeatChecksAuditStatus = 'GENERATED' | 'PROCESSING' | 'FAILED' | 'REQUESTED'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSuccess = <TData>(result: ApiResult): result is ApiSuccessResult<TData> =>
  typeof result.success === 'boolean' && result.success === true

export const isFailure = <TData>(result: ApiResult): result is ApiFailureResult<TData> =>
  typeof result.success === 'boolean' && result.success === false

export type FieldFilter = {
  type: FilterType
  dataType: FilterInputType
  from?: string
  to?: string
  values?: string[]
  fallbackField?: string
}

export type Filter = {
  [key: string]: FieldFilter
}

export type Aggregate = {
  field: string
  includeMissing: boolean
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export interface PageRequest {
  page?: number
  pageSize?: number
}

export interface SortRequest {
  sortBy?: string
  sortOrder?: SortOrder
}

interface PageOf<T, M> {
  totalRecords: number
  records: T[]
  metadata?: M
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PageResult<T, M = any> extends ApiSuccessResult<PageOf<T, M>> { }

export type SearchMetadata = {
  aggregations: AggregateMetadata
}

export type AggregateMetadata = {
  [key: string]: string[]
}

export type AuditType = 'SEARCH' | 'BULK_SEARCH' | 'PASSHOLDER' | 'REPORT' | 'EXPORT' | 'LINKED_PROFILE'

export interface AuditSearchSummary {
  audit_type: AuditType
  justification: string
  terms: string
  filters: AuditSearchFilter[]
  record_reference: string
  report_id: string
  report_index: string
  success: boolean
  username: string
  date: string
  compare_record?: string | null
}

export interface AuditSearchFilter {
  field: string
  values: string[]
}

export type IssueSeverity = 'ERROR' | 'WARNING'

export interface AuditRecordIssue {
  message?: string
  severity?: IssueSeverity
  field?: string
  value?: string
}
export interface BatchRecord extends AuditRecordIssue {
  aphids_record_reference: string
  record_reference: string
  record_position: number
  record_severity?: IssueSeverity
  person_reference?: string
  uuid: string
}

export type BatchStatus = 'UPLOADED' | 'PROCESSING' | 'FAILED' | 'COMPLETED' | 'TIMEDOUT'

export interface BatchSummary {
  timestamp: string
  source_type: DataSourceType
  source: string
  status: BatchStatus
  record_count: number
  error_row_count: string
  error_count: number
  warning_row_count: string
  warning_count: number
  issue_count: string
  filename: string
  uuid: string
  md5_hash: string
  user: string
  failure_reason: string
}

export type UserProfile = {
  hasAcceptedTerms: boolean
  lastLogin?: string
}

export type FeedbackConfirmation = {
  referenceNumber: string
}

export type FeedbackScore = '1' | '2' | '3' | '4' | '5'

export type ReportStatus = 'processing' | 'completed' | 'failed' | 'completed_with_warnings'

export interface ReportSummary {
  id: string
  index: string
  name: string
  created_at: string
  status: ReportStatus
}

export interface AccessHistory {
  status: string,
  timestamp: string
}

export interface PassHolderSummary {
  PROCESSING_timestamp?: string
  aphids_record_reference?: string
  application_completion_date?: string
  application_id?: string
  application_status?: string
  iata?: string
  icao?: string
  job_title?: string
  pass?: Pass
  person?: Person
  record_reference?: string
  source_type?: DataSourceType
  saved_id?: string
  crc_last_check_date?: string
  site_location?: string
  access_history?: AccessHistory[]
}

export interface PassHolderSearchSummary {
  active: boolean
  personId: string
  forename: string
  surname: string
  middle_names: string
  date_of_birth: string
  card_status: string
  card_type: string
  application_status: string
  organisation_code: string
  organisation_name: string
  source_type: DataSourceType
  record_reference: string
  highlights?: SearchHighlights
  saved_id?: string
  site_location: string
}

export interface PassHolderBulkSearchRecord {
  givenNames: string
  surname: string
  others: string[]
}

export interface SavedProfileSummary {
  id: string
  forename: string
  surname: string
  middle_names: string
  date_of_birth: string
  justification: string
  saved_on: string
  active: boolean
  card_status: string
  card_type: string
  application_status: string
  organisation_code: string
  organisation_name: string
  source_type: DataSourceType
  record_reference: string
}

export interface SearchHighlights {
  [field: string]: string[]
}

export enum AccessibilityFeedbackReplyWanted {
  Yes = 'yes',
  No = 'no'
}

export enum AccessibilityFeedbackLocation {
  WholeSite = 'the-whole-site',
  SpecificPage = 'specific-page'
}

export enum SupportFeedbackType {
  Access = 'access',
  Issue = 'issue',
  Request = 'request'
}

export enum SupportRequestType {
  ChangeOTPDevice = 'change-otp',
  CreateAccount = 'create-account',
  RemoveAccount = 'remove-account',
  Other = 'other'
}

export enum SupportRequestTargetAccount {
  Own = 'my own',
  SomeoneElse = 'someone else'
}

export type SupportRequestSomeoneElse = {
  targetAccount: typeof SupportRequestTargetAccount.SomeoneElse
  forename: string
  surname: string
  email: string
  message: string
}

export type SupportRequestOwn = {
  targetAccount: typeof SupportRequestTargetAccount.Own
  message: string
}

export type SupportRequest =
  SupportRequestSomeoneElse |
  SupportRequestOwn

export enum FilterWildcard {
  StartsWith = 0,
  Contains = 1
}

export enum OrganisationType {
  AirCargo = 'AIR_CARGO',
  AirCarrier = 'AIR_CARRIER',
  Airport = 'AIRPORT',
  CivilAviationAuthority = 'CAA',
  InternationalRail = 'INTERNATIONAL_RAIL',
  MaritimePort = 'MARITIME_PORT'
}

export type BulkExportPreviewResult = {
  preview: object[]
  count: number | null
}

export type ExportStatus = 'REQUESTED' | 'PROCESSING' | 'FAILED' | 'COMPLETED' | 'TIMEDOUT' | 'EXPIRED' | 'PARTIAL'

export interface BulkExportSummary {
  export_id: string
  requested_date: string
  status: ExportStatus
  record_count?: number
  organisations: string[]
  justification: string
  error_count: number
  message: string
  user: string
  is_external?: boolean
  filters?: IBulkExportFilters
}

export enum ClientErrorType {
  PassHolderSearchTerms = 'PASSHOLDER_TERMS'
}

export enum StatusCode {
  Green = 'GREEN',
  Amber = 'AMBER',
  Red = 'RED'
}

export type ServiceStatuses = {
  [key: string]: ServiceStatus
}

export type ServiceStatus = {
  name: string
  status: IStatus
  issues?: string[]
}

export type InfrastructureStatuses = {
  [key: string]: IInfrastructure
}

export interface IHealthResponse {
  infrastructure: InfrastructureStatuses
  services: {

  }
}

export interface IStatus {
  code: StatusCode
  text: string
}

export interface IInfrastructure {
  name: string
  status: IStatus
  issues: string[]
}

export type Phase = 'alpha' | 'beta'

export interface IReleaseInformation {
  release_number?: string
  release_phase?: Phase
  maintenance_status?: MaintenanceSummary
  services?: object
}

export type MaintenanceStatus = 'DISABLED' | 'WARNING' | 'ENABLED'

interface MaintenanceSummary {
  status: MaintenanceStatus
  warning_message?: string
  maintenance_message?: string
}

export interface IPortalConfig {
  bearerOnly?: boolean
  clientId: string
  realm: string
  url?: string
  termsVersion: string
}
interface OrganisationSummaryBase {
  code: string
  name: string
  type: OrganisationType
  custom_config?: OrganisationConfig
}

export interface OrganisationSummary extends OrganisationSummaryBase {
  iata?: string
  icao?: string
  region?: string
}

export interface UserOrganisationSummary extends OrganisationSummary {
  source_type: DataSourceType[]
}

export interface IUserOrganisationResponse {
  orgs: UserOrganisationSummary[]
  config: IReferenceDataSummary
}

export type IReferenceDataSummary = Record<string, OrganisationConfig>

export interface OrganisationConfig {
  description?: string
  card_format?: OrganisationConfigCardFormat
  access_area?: OrganisationConfigAccessAreas
  issue_date?: OrganisationConfigIssueDate
}

export interface OrganisationConfigIssueDate {
  description?: string;
}

export interface OrganisationConfigCardFormat {
  description?: string
  cards?: OrganisationConfigCards[]
}

export interface OrganisationConfigCards {
  colour: string
  description: string
}

export interface OrganisationConfigAccessAreas {
  description?: string
  areas?: OrganisationConfigArea[]
}

export interface OrganisationConfigArea {
  number: string
  description: string
}

export type DataProviderStatus = 'NOT_RECENTLY_UPLOADED' | 'RECENTLY_ONBOARDED' | 'RECENTLY_UPLOADED' | 'NOT_ONBOARDED'

export interface DataProviderSourceTypeSummary {
  sourceType: DataSourceType
  date?: string
  status: DataProviderStatus
}

export interface DataProviderSummary extends OrganisationSummary {
  source_type_upload?: DataProviderSourceTypeSummary[]
}

export type SavedProfileResult = {
  id: string
}
