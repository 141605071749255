import { reset } from '../results/actions'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const BULKSEARCH_TYPE = 'BulkSearch/Type'

export type BulkSearchTypeState = {
  isPhoneNumberSearch: boolean
}

export const initialState: BulkSearchTypeState = {
  isPhoneNumberSearch: false
}

const slice = createSlice({
  name: BULKSEARCH_TYPE,
  initialState,
  reducers: {
    setBulkSearchType(state, action: PayloadAction<boolean>) {
      state.isPhoneNumberSearch = action.payload || false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
  }
})

export const {
  setBulkSearchType
} = slice.actions

export default slice.reducer
